.replied-message-container {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
}
.replied-message-container .vertical-bar {
  flex-shrink: 0;
  width: 3px;
  height: 90%;
  background-color: orange;
}
.replied-message-container .text-block {
  margin-left: 5px;
  flex-grow: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
}
.replied-message-container .text-block .ant-space {
  min-width: 0;
}
.replied-message-container .text-block .ant-space .ant-space-item {
  display: flex;
}
.replied-message-container .text-block .ant-space .ant-space-item .message-preview {
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}
.replied-message-container .remove {
  flex-shrink: 0;
  margin-left: auto;
  margin-right: 10px;
  align-self: center;
}
