.ant-badge {
  height: 100%;
  width: 100%;
}
.chat-layout-icon-sidepanel {
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.chat-layout-icon-sidepanel .ant-layout-sider-children {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chat-layout-icon-sidepanel .chat-layout-icon-sidepanel-header {
  height: 60px;
  width: 100%;
  background-color: #61B7E6;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chat-layout-icon-sidepanel ::-webkit-scrollbar {
  display: none;
}
.chat-layout-icon-sidepanel .icon-list {
  padding-top: 4px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 0;
  background-color: whitesmoke;
  overflow: auto;
}
.chat-layout-icon-sidepanel .icon-list .icon-list-item {
  height: 70px;
  width: 100%;
  padding: 7px;
  margin-top: 1px;
}
.chat-layout-icon-sidepanel .icon-list .icon-list-item svg {
  fill: white;
}
.chat-layout-icon-sidepanel .icon-list .icon-list-item .rounded-background {
  height: 100%;
  width: 100%;
  border-radius: 35%;
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  background-color: #61B7E6;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  color: white;
}
.chat-layout-icon-sidepanel .icon-list .icon-list-item .rounded-background * {
  pointer-events: none;
  margin: auto;
}
.chat-layout-icon-sidepanel .icon-list .icon-list-item .rounded-background .text {
  line-height: normal;
  display: flex;
  flex-direction: column;
  font-size: 15px;
}
.chat-layout-icon-sidepanel .icon-list .icon-list-item .rounded-background:hover {
  background-color: white;
  color: #61B7E6;
  cursor: pointer;
}
.chat-layout-icon-sidepanel .icon-list .icon-list-item .rounded-background:hover svg {
  fill: #61B7E6;
}
.chat-layout-icon-sidepanel .icon-list .icon-list-item .rounded-background-clicked {
  background-color: white;
  color: #61B7E6;
  cursor: pointer;
}
.chat-layout-icon-sidepanel .icon-list .icon-list-item .rounded-background-clicked svg {
  fill: #61B7E6;
}
