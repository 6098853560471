.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0 0 0 21px;
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding: 0 0 0 21px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  left: 0;
  padding: 0;
}
